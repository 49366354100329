import { useRef, useEffect, useLayoutEffect, useState, type MutableRefObject } from 'react';

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRGBA(hex: string, alpha: number): string {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    const r = result ? parseInt(result[1], 16) : 255;
    const g = result ? parseInt(result[2], 16) : 255;
    const b = result ? parseInt(result[3], 16) : 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
export function getSupportsLineClamp(lineClamp?: number): boolean {
    if (typeof window === 'undefined' || typeof CSS === 'undefined' || !CSS.supports) return false;
    return (
        CSS.supports('(--cssVar: red)') && CSS.supports('-webkit-line-clamp', '1') && !!lineClamp
    );
}

// don't useLayoutEffect on server https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85#file-uselayouteffect-ssr-md
export const useIsomorphicLayoutEffect =
    typeof window === 'undefined' ? useEffect : useLayoutEffect;

export const useClampedHeight = (
    supportsLineClamp: boolean,
    collapsedHeight: number | string | undefined,
    contentRef: MutableRefObject<HTMLElement | null>,
    lineClampClass: string,
    lineClamp?: number
): number | string | undefined => {
    const [clampedHeight, setClampedHeight] = useState<number | null>(null);
    const lineClampRef = useRef<number | null | undefined>(null);
    useIsomorphicLayoutEffect(() => {
        if (
            supportsLineClamp &&
            contentRef.current &&
            (!clampedHeight || lineClampRef.current !== lineClamp)
        ) {
            // eslint-disable-next-line no-console
            console.warn('dibs-elements | Expanding Area | lineClamp overrides collapsedHeight.');
            const width = contentRef.current.clientWidth;
            const clone = contentRef.current.cloneNode(true) as HTMLElement;
            clone.style.width = width.toString();
            clone.style.cssText = `width: ${width}px; --expandingAreaLineClamp: ${lineClamp}; position: absolute; visibility: hidden; z-index: -1`;
            clone.classList.add(lineClampClass);
            document.body.append(clone);
            setClampedHeight(clone.clientHeight);
            clone.remove();
        }
        lineClampRef.current = lineClamp;
    }, [contentRef, lineClamp, clampedHeight, supportsLineClamp]);
    // clampedHeight will be null on initial render, use collapsedHeight as a fallback
    return supportsLineClamp && clampedHeight ? clampedHeight : collapsedHeight;
};

export function getContentHeight(
    heightWrapper: MutableRefObject<HTMLElement | null>,
    maxHeight: number | string | undefined,
    supportsLineClamp: boolean
): string | number | undefined {
    // line-clamp changes the clientHeight, so it cannot be reliably used when determining
    // expanded height for a clamped ExpandingArea
    const heightProperty = supportsLineClamp ? 'scrollHeight' : 'clientHeight';
    const maxHeightNumber: number | null =
        (typeof maxHeight === 'string' && parseInt(maxHeight)) ||
        (typeof maxHeight === 'number' && maxHeight) ||
        null;
    if (maxHeightNumber && heightWrapper.current) {
        return Math.min(heightWrapper.current?.[heightProperty], maxHeightNumber);
    } else {
        return heightWrapper.current?.[heightProperty];
    }
}

/**
 * @generated SignedSource<<9c67cba77a9c2aa60b1893eadfe74321>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileFooter_viewer$data = {
  readonly footerNftLink: boolean | null;
  readonly footerQuestionsAnswersLink: boolean | null;
  readonly sitemapLinks: boolean | null;
  readonly wpEditorialLink: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"FooterBottomRow_viewer" | "FooterSubscribe_viewer">;
  readonly " $fragmentType": "MobileFooter_viewer";
};
export type MobileFooter_viewer$key = {
  readonly " $data"?: MobileFooter_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileFooter_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileFooter_viewer",
  "selections": [
    {
      "alias": "footerNftLink",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "NFT_LINK"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"NFT_LINK\")"
    },
    {
      "alias": "footerQuestionsAnswersLink",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "QUESTIONS_LINK"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"QUESTIONS_LINK\")"
    },
    {
      "alias": "sitemapLinks",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "SITEMAP"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"SITEMAP\")"
    },
    {
      "alias": "wpEditorialLink",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "WP_EDITORIAL_LINK"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL_LINK\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FooterSubscribe_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FooterBottomRow_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "67e8a192259afb304e31f6962516f25f";

export default node;

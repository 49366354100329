import { useState, useEffect, type FC, type ReactNode } from 'react';

import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';
import { RotatingArrow } from 'dibs-elements/exports/RotatingArrow';

import styles from './MobileFooterDrawer.scss';

type Props = {
    title: ReactNode;
    content: ReactNode;
    isExpanded?: boolean;
    expandable?: boolean;
};

export const MobileFooterDrawer: FC<Props> = ({
    title,
    content,
    isExpanded = false,
    expandable = true,
}) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);

    return (
        <ExpandingArea
            wrapperClass={styles.container}
            expandingAreaClass={styles.content}
            labelClass={styles.label}
            showOverflow
            hideCollapsedContent
            label={
                <h2 className={styles.header}>
                    {title}
                    {expandable && (
                        <RotatingArrow
                            size="small"
                            type="blackFill"
                            direction={expanded ? 'up' : 'down'}
                        />
                    )}
                </h2>
            }
            expanded={expanded}
            onClick={() => {
                if (expandable) {
                    setExpanded(!expanded);
                }
            }}
            showGradient={false}
        >
            {content}
            <div className={styles.buffer} />
        </ExpandingArea>
    );
};

import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import AppStoreLink from '../Footer/AppStoreLink/AppStoreLink';

import styles from './MobileFooterAppStoreRow.scss';

const MobileFooterAppStoreRowComponent: FC = () => {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.header}>
                    <FormattedMessage
                        id="footer.mobileFooterAppStoreRow.header"
                        defaultMessage="Browsing Made Beautiful"
                    />
                </div>
                <div className={styles.subheader}>
                    <FormattedMessage
                        id="footer.mobileFooterAppStoreRow.subheader"
                        defaultMessage="It's all in the app"
                    />
                </div>
            </div>
            <AppStoreLink />
        </div>
    );
};

export const MobileFooterAppStoreRow = MobileFooterAppStoreRowComponent;

import { type FC } from 'react';
import { Link } from 'dibs-elements/exports/Link';
import AppStoreButtonDark from 'dibs-icons/exports/legacy/AppStoreDark';
import { trackNavigationClick } from '../../../helpers/trackingHelpers';

import appLinks from '../../../helpers/appLinks';

import style from './AppStoreLink-style.scss';

const AppStoreLink: FC = () => {
    return (
        <Link
            className={style.link}
            href={appLinks.FOOTER_NAV_LINK}
            dataTn="footer-download"
            onClick={event => {
                trackNavigationClick({ label: 'app store link' }, event);
            }}
        >
            <AppStoreButtonDark className={style.icon} />
        </Link>
    );
};

export default AppStoreLink;

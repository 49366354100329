import { Component } from 'react';
import { bool, object } from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import { getBuyerId } from 'dibs-cookie-jar';
import { MobileFooter } from '../MobileFooter/MobileFooter';

import style from './MobileFooterWrapper.scss';

class MobileFooterWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = { isClient: false };
        this.updateUserState = this.updateUserState.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState(prevState => {
            return {
                ...prevState,
                isClient: true,
            };
        }, this.updateUserState);
    }

    componentDidUpdate(prevProps) {
        if (this.props.updateUserState && !prevProps.updateUserState) {
            this.updateUserState();
        }
    }

    updateUserState() {
        const userId = getBuyerId(document.cookie) || '';
        const hasUserId = !!userId;
        const refetchVariables = () => ({
            userId: userId,
            hasUserId,
        });

        this.props.relay.refetch(refetchVariables);
    }

    render() {
        const { viewer, hideFooter } = this.props;

        return (
            <footer className={style.footer} hidden={hideFooter}>
                <MobileFooter viewer={viewer} />
            </footer>
        );
    }
}

MobileFooterWrapper.propTypes = {
    relay: object,
    updateUserState: bool,
    hideFooter: bool,
    viewer: object,
};

const mapStateToProps = ({ header }) => {
    const { updateUserState, hideFooter } = header;
    return {
        updateUserState,
        hideFooter,
    };
};

export default createRefetchContainer(
    connect(mapStateToProps)(MobileFooterWrapper),
    {
        viewer: graphql`
            fragment MobileFooterWrapper_viewer on Viewer {
                user(userId: $userId) @include(if: $hasUserId) {
                    __typename
                }
                ...MobileFooter_viewer
            }
        `,
    },
    graphql`
        query MobileFooterWrapperRefetchQuery($userId: String!, $hasUserId: Boolean!) {
            viewer {
                ...MobileFooterWrapper_viewer
            }
        }
    `
);

import { type FC, type ReactNode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import FooterRenderer from './MobileFooterRenderer/MobileFooterRenderer';

type MobileFooterRenderArgs = {
    node: Element;
    Wrapper: FC<{ children: ReactNode }>;
};

export async function render({ Wrapper, node }: MobileFooterRenderArgs): Promise<void> {
    hydrateRoot(
        node,
        <Wrapper>
            <FooterRenderer />
        </Wrapper>,
        { identifierPrefix: 'footer' }
    );
}

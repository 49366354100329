import { type FunctionComponent, useState, useRef, type ReactElement } from 'react';
import { ExpandingArea, type ExpandingAreaProps } from './index';
import { FormattedMessage } from 'dibs-react-intl';
import scrollIntoView from 'scroll-into-view';
import classNames from 'classnames';
import { ReadMoreButton } from './ReadMoreButton';

import styles from './ExpandingAreaWithReadMore.scss';

const READ_MORE = 'readMore';
const READ_LESS = 'readLess';

export type OnReadMoreClickArgs = { type: typeof READ_MORE | typeof READ_LESS };

type ExpandingAreaWithReadMoreProps = Omit<ExpandingAreaProps, 'expanded'> & {
    readMore?: ReactElement;
    readLess?: ReactElement;
    buttonClass?: string;
    onReadMoreClick?: ({ type }: OnReadMoreClickArgs) => void;
    initialExpanded?: boolean;
    expandingAreaWrapperClass?: string;
};

const readMoreMessage = (
    <FormattedMessage id="expandingArea.continueReading" defaultMessage="Continue Reading" />
);
const readLessMessage = <FormattedMessage id="expandingArea.readLess" defaultMessage="Read Less" />;

export const ExpandingAreaWithReadMore: FunctionComponent<ExpandingAreaWithReadMoreProps> = ({
    readMore = readMoreMessage,
    readLess = readLessMessage,
    wrapperClass,
    expandingAreaWrapperClass,
    buttonClass,
    onReadMoreClick,
    initialExpanded,
    ...props
}) => {
    const [isExpanded, setIsExpanded] = useState(initialExpanded || false);
    const [isContentExpandable, setIsContentExpandable] = useState(false);
    const contentRef = useRef(null);

    return (
        <div className={wrapperClass} ref={contentRef}>
            <ExpandingArea
                {...props}
                wrapperClass={classNames(styles.expandingAreaWrapper, expandingAreaWrapperClass)}
                expanded={isExpanded}
                setIsContentExpandable={setIsContentExpandable}
            />

            {isContentExpandable && (
                <ReadMoreButton
                    isExpanded={isExpanded}
                    className={buttonClass}
                    onClick={() => {
                        if (onReadMoreClick) {
                            onReadMoreClick({ type: isExpanded ? READ_LESS : READ_MORE });
                        }
                        if (isExpanded && contentRef && contentRef.current) {
                            scrollIntoView(contentRef.current, { time: 200 });
                        }
                        setIsExpanded(!isExpanded);
                    }}
                    readLessMessage={readLess}
                    readMoreMessage={readMore}
                />
            )}
        </div>
    );
};

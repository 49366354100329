import { type FunctionComponent, type ReactElement } from 'react';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';

import style from './ReadMoreButton.scss';

type ReadMoreButtonProps = {
    isExpanded?: boolean;
    className?: string;
    onClick: () => void;
    readLessMessage: ReactElement;
    readMoreMessage: ReactElement;
};

export const ReadMoreButton: FunctionComponent<ReadMoreButtonProps> = ({
    isExpanded,
    className,
    onClick,
    readLessMessage,
    readMoreMessage,
}) => {
    const dataTn = isExpanded ? 'read-less' : 'read-more';

    return (
        <Link
            className={classnames(style.button, className)}
            underline="none"
            onClick={onClick}
            dataTn={dataTn}
        >
            {isExpanded ? readLessMessage : readMoreMessage}
        </Link>
    );
};

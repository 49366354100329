import { type FC } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import MobileFooterWrapper from '../MobileFooterWrapper/MobileFooterWrapper';
import mobileFooterRootQuery, {
    initialVariables,
    type mobileFooterRootQueryType,
} from '../../queries/mobileFooterRootQuery';

const MobileFooterRenderer: FC = () => {
    const data = useLazyLoadQuery<mobileFooterRootQueryType>(
        mobileFooterRootQuery,
        initialVariables,
        { fetchPolicy: 'store-only' }
    );

    return <MobileFooterWrapper viewer={data.viewer} />;
};

export default MobileFooterRenderer;

import { graphql } from 'react-relay';
export type { mobileFooterRootQuery as mobileFooterRootQueryType } from './__generated__/mobileFooterRootQuery.graphql';

export default graphql`
    query mobileFooterRootQuery($userId: String = "", $hasUserId: Boolean!) {
        viewer {
            ...MobileFooterWrapper_viewer
        }
    }
`;

export const initialVariables = {
    hasUserId: false,
    userId: '',
};
